import { Route } from "react-router-dom";
import Home from "../pages/home";
import Login from "../pages/login";
import Signup from "../pages/signup";
import PasswordReset from "../pages/passwordreset";
import ConfirmPasswordReset from "pages/confirmpasswordreset";

export const landingRoutes = [
  {
    type: Route,
    path: "/",
    component: Home,
    exact: true,
  },
  {
    type: Route,
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    type: Route,
    path: "/signup",
    component: Signup,
    exact: true,
  },
  {
    type: Route,
    path: "/password/reset",
    component: PasswordReset,
    exact: true,
  },

  {
    type: Route,
    path: "/password/confirm/reset/:token",
    component: ConfirmPasswordReset,
    exact: true,
  },
];
