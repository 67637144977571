import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";

import Code from "../../assets/images/code.png";
import Loader from "assets/images/ball-rolling.svg";

import { getSingleTasksApi, addUserTasksApi, submitTaskApi } from "api/tasks";

const SingleTask = ({ match }) => {
  const [singleTask, setSingleTask] = useState({});
  const [userTaskUpdate, setUserTaskUpdate] = useState([]);
  const [solution, setSolution] = useState({
    title: "",
    url: "",
    questions: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    document && document.querySelector("body").classList.add("bg-white");
  }, []);

  const taskSlug = match?.params?.id;

  const { refetch: getSingleTaskFetch, isLoading } = useQuery(
    "getSingleTask",
    async () => {
      const response = await getSingleTasksApi(taskSlug);
      return response;
    },
    {
      onSuccess: (data) => {
        setSingleTask(data?.data?.task[0]);
        setUserTaskUpdate(data?.data?.userTaskUpdate);
      },
      onError: (err) => {
        if (err.response.data.statusCode === 404) {
          window.location.href = "/not-found";
        }
      },
    }
  );

  const submitTask = useMutation(
    "submitTask",
    async (data) => {
      const response = await submitTaskApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        toast.success("You've submitted this task succesfully!");
        getSingleTaskFetch();
      },
      onError: (error) => {
        toast.error("Task could not be submitted. Try Again!");
        getSingleTaskFetch();
      },
    }
  );

  const startTask = useMutation(
    "startTaskByUser",
    async (data) => {
      const response = await addUserTasksApi(data);
      return response;
    },
    {
      onSuccess: () => {
        toast.success("You've started this task succesfully!");
        getSingleTaskFetch();
      },
      onError: () => {
        toast.error("Failed to start the task!");
      },
    }
  );

  useEffect(() => {
    getSingleTaskFetch();
  }, [getSingleTaskFetch]);

  const submitSolution = () => {
    if (!solution.title || !solution.url)
      return setErrorMessage("Title or Link is required!");

    submitTask.mutate({
      reviewtitle: solution.title,
      reviewlink: "https://" + solution.url,
      reviewquestions: solution.questions || "",
      utid: userTaskUpdate?.[0]?.utid,
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    setSolution((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  // const { reviewtitle, reviewlink, reviewquestions, utid } = req.body;

  return (
    <>
      <div className="bg-dark pb-5 pt-8">
        <div className="container">
          <div className="row">
            {isLoading ? (
              <img
                src={Loader}
                alt="loader"
                className="rounded-circle"
                width="82"
                height="82"
              />
            ) : (
              <div className="col-lg-12 mb-5 mb-lg-0 position-relative text-center">
                <img
                  src={Code}
                  alt="..."
                  className="rounded-circle "
                  width="38"
                  height="38"
                />
                <h1 className="text-white">{singleTask?.title}</h1>
                <p className="mb-5 text-white">{singleTask?.subtitle}</p>
                <p className="mb-5  text-white">
                  {" "}
                  Category: {singleTask?.category}
                </p>
                <p className="mb-5 text-white">
                  Level: &nbsp;
                  <span
                    className="font-size-sm"
                    style={{
                      padding: "5px 10px",
                      background: "#4dbf4d",
                      color: "white",
                      borderRadius: "20px",
                    }}
                  >
                    {singleTask?.level?.charAt(0)?.toUpperCase() +
                      singleTask?.level?.slice(1)}
                  </span>
                  , &nbsp;Points: {singleTask?.points}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          Loading Task...
        </div>
      ) : (
        <div className="container">
          <div className="row pt-8">
            <div className="col-lg-10 col-xl-8 mx-lg-auto">
              <div className="mb-8">
                <h3 className="">Task Description</h3>
                <div
                  className="mb-6 line-height-md"
                  dangerouslySetInnerHTML={{ __html: singleTask?.description }}
                />
                   {singleTask?.imgurl !== "none" && (
                  <div>
                    <h3 className="mb-5">Expected Similar Output</h3>
                    <div className="row row-cols-lg-2 mb-5">
                      <div className="col-md">
                        <img src={singleTask?.imgurl} alt="preview" width="600px" />
                      </div>
                    </div>
                  </div>
                )}

                <h3 className="mb-5">What you'll learn</h3>
                <div className="row row-cols-lg-2 mb-5">
                  <div className="col-md">
                    <ul className="list-style-v1 list-unstyled">
                      {singleTask?.learnlist.split(",").map((value, index) => {
                        return (
                          <li key={`learn-list-${index}-${value}`}>{value}</li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

             
                {singleTask?.criteria === "none" ? (
                  ""
                ) : (
                  <div>
                    {/* <h3 className="mb-5">Acceptance Criteria</h3>
                <ul className="list-style-v2 list-unstyled">
                  <li>
                    We do not require any previous experience or pre-defined
                    skills to take this course. A great orientation would be
                    enough to master UI/UX design.
                  </li>
                  <li>A computer with a good internet connection.</li>
                  <li>Adobe Photoshop (OPTIONAL)</li>
                </ul> */}
                  </div>
                )}
              </div>
              <div className="mb-8">
                <h3 className="mb-5">Resources:</h3>
                <div
                  id="CurriculumcollapseOne"
                  className="collapse show"
                  aria-labelledby="curriculumheadingOne"
                  data-parent="#accordionCurriculum"
                >
                  <div className="border-bottom border-top px-5 py-4 min-height-70">
                    {singleTask?.resources.split(",").map((value, index) => {
                      return (
                        <a
                          className="d-flex align-items-center me-auto mb-6"
                          key={`resource-list-${index}-${value}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={value}
                        >
                          <div className="text-secondary d-flex">
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.5717 0H4.16956C4.05379 0.00594643 3.94322 0.0496071 3.85456 0.124286L0.413131 3.57857C0.328167 3.65957 0.280113 3.77191 0.280274 3.88929V16.8514C0.281452 17.4853 0.794988 17.9988 1.42885 18H12.5717C13.1981 17.9989 13.7086 17.497 13.7203 16.8707V1.14857C13.7191 0.514714 13.2056 0.00117857 12.5717 0ZM8.18099 0.857143H10.6988V4.87714L9.80527 3.45214C9.76906 3.39182 9.71859 3.3413 9.65827 3.30514C9.45529 3.18337 9.19204 3.24916 9.07027 3.45214L8.18099 4.87071V0.857143ZM3.7367 1.46786V2.66143C3.73552 3.10002 3.38029 3.45525 2.9417 3.45643H1.74813L3.7367 1.46786ZM12.8546 16.86C12.8534 17.0157 12.7274 17.1417 12.5717 17.1429H1.42885C1.42665 17.1429 1.42445 17.143 1.42226 17.143C1.26486 17.1441 1.13635 17.0174 1.13527 16.86V4.32214H2.9417C3.85793 4.31979 4.60006 3.57766 4.60242 2.66143V0.857143H7.31527V5.23286C7.31345 5.42593 7.37688 5.61391 7.49527 5.76643C7.67533 5.99539 7.98036 6.08561 8.25599 5.99143L8.28813 5.98071C8.49272 5.89484 8.66356 5.7443 8.77456 5.55214L9.44099 4.48071L10.1074 5.55214C10.2184 5.7443 10.3893 5.89484 10.5938 5.98071C10.8764 6.0922 11.1987 6.00509 11.3867 5.76643C11.5051 5.61391 11.5685 5.42593 11.5667 5.23286V0.857143H12.5717C12.7266 0.858268 12.8523 0.982982 12.8546 1.13786V16.86Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M10.7761 14.3143H3.22252C2.98584 14.3143 2.79395 14.5062 2.79395 14.7429C2.79395 14.9796 2.98584 15.1715 3.22252 15.1715H10.7761C11.0128 15.1715 11.2047 14.9796 11.2047 14.7429C11.2047 14.5062 11.0128 14.3143 10.7761 14.3143Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M10.7761 12.2035H3.22252C2.98584 12.2035 2.79395 12.3954 2.79395 12.6321C2.79395 12.8687 2.98584 13.0606 3.22252 13.0606H10.7761C11.0128 13.0606 11.2047 12.8687 11.2047 12.6321C11.2047 12.3954 11.0128 12.2035 10.7761 12.2035Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M10.7761 10.0928H3.22252C2.98584 10.0928 2.79395 10.2847 2.79395 10.5213C2.79395 10.758 2.98584 10.9499 3.22252 10.9499H10.7761C11.0128 10.9499 11.2047 10.758 11.2047 10.5213C11.2047 10.2847 11.0128 10.0928 10.7761 10.0928Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M10.7761 7.98218H3.22252C2.98584 7.98218 2.79395 8.17407 2.79395 8.41075C2.79395 8.64743 2.98584 8.83932 3.22252 8.83932H10.7761C11.0128 8.83932 11.2047 8.64743 11.2047 8.41075C11.2047 8.17407 11.0128 7.98218 10.7761 7.98218Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <div className="ms-4">{value}</div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
              {userTaskUpdate && userTaskUpdate.length < 1 && (
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mw-md-300p"
                    onClick={() => {
                      startTask.mutate({
                        taskId: singleTask?.taskid,
                      });
                    }}
                  >
                    {startTask.isLoading ? "STARTING TASK..." : "START TASK"}
                  </button>
                </div>
              )}

              {isLoading ||
              userTaskUpdate?.length < 1 ||
              userTaskUpdate?.[0]?.status !== "ongoing" ? (
                ""
              ) : (
                <div className="border shadow rounded p-6 p-md-9 border-top">
                  <h3 className="mb-2">Submit Solution</h3>
                  <div>
                    {errorMessage && (
                      <div style={styles.errorMessageBox}>{errorMessage}</div>
                    )}
                    <div className="form-group mb-6">
                      <label for="exampleInputTitle1">
                        Solution title{" "}
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control placeholder-1"
                        id="exampleInputTitle1"
                        placeholder="e.g Calculator App with JS"
                        name="title"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group mb-6">
                      <label for="exampleFormControlTextarea1">
                        Link/URL to Solution:
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <div class="input-group">
                        <span class="input-group-addon">https:// &nbsp;</span>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="inputGroupSuccess1Status"
                          name="url"
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* https://{" "}
                      <textarea
                        className="form-control placeholder-1"
                        id="exampleFormControlTextarea1"
                        rows="1"
                        placeholder="This could be repo or gist or codepen or google drive link"
                        name="url"
                        onChange={handleInputChange}
                      ></textarea> */}
                    </div>
                    <div className="form-group mb-6">
                      <label for="exampleFormControlTextarea1">
                        Questions for the community
                      </label>
                      <p>
                        Please ensure you add specific questions you'd like
                        people to answer if you want feedback. Specific
                        questions are more likely to receive helpful feedback
                        than general statements like "Feedback welcome". <br />
                      </p>
                      <textarea
                        className="form-control placeholder-1"
                        id="exampleFormControlTextarea1"
                        rows="6"
                        placeholder="Things to consider when asking for specific feedback include:
                      What did you find difficult while building the project? Do
                      you have any questions about best practices?
                    "
                        name="questions"
                        onChange={handleInputChange}
                      ></textarea>
                    </div>

                    <button
                      className="btn btn-primary btn-block mw-md-300p"
                      onClick={() => submitSolution()}
                      disabled={
                        !solution.title || !solution.url || submitTask.isLoading
                      }
                    >
                      {submitTask.isLoading
                        ? "SUBMITING..."
                        : "SUBMIT SOLUTION"}
                    </button>
                  </div>
                </div>
              )}
              {userTaskUpdate?.[0]?.status === "submitted" && (
                <div
                  className="text-center"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Status: Submitted & waiting for feedback
                </div>
              )}

              {userTaskUpdate?.[0]?.status === "reviewed" && (
                <div
                  className="text-center"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Already Reviewed:{" "}
                  <a href={`/review-task/${userTaskUpdate?.[0]?.utid}`}>
                    See review(s) here
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    textAlign: "left",
    marginBottom: "20px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default SingleTask;
