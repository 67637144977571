import React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Subscribe from "../../components/Subscribe/Subscribe";
import Loader from "assets/images/ball-rolling.svg";
import NotStarted from "assets/images/not-started.png";
// import Hero from "components/Hero";

import { getUserTasksApi } from "api/tasks";

const MyTasks = () => {
  useEffect(() => {
    document && document.querySelector("body").classList.add("bg-white");
  }, []);

  const [allTasks, setAllTasks] = useState([]);

  const { isLoading, isError } = useQuery(
    "getUserTasks",
    async () => {
      const response = await getUserTasksApi();
      return response;
    },
    {
      onSuccess: (data) => {
        setAllTasks(data?.data?.tasks);
      },
    }
  );

  return (
    <>
      <section className=" border-top" style={{ backgroundImage: "none" }}>
        <div className="container">
          <div className="row mb-11">
            <div className="col-md-9 col-lg-9 col-xl-9 py-0">
              <div className="container">
                {/* <Hero /> */}
                <br />
                <nav aria-label="breadcrumb ">
                  <ol className="breadcrumb breadcrumb-scroll justify-content-left">
                    <li className="breadcrumb-item">
                      <a href="/home">
                        <span className="">
                          <i className="fas fa-arrow-left font-size-md"></i>
                          &nbsp; Back Home
                        </span>
                      </a>
                    </li>
                    <li
                      className="breadcrumb-item text-gray-800 active"
                      aria-current="page"
                    >
                     My Tasks
                    </li>
                  </ol>
                </nav>
                <br />
                <h2 className="fw-semi-bold">
                <i class="fa fa-tasks"></i> &nbsp;My Tasks
                </h2>
                {isError && (
                  <span
                    className="ms-2"
                    style={{
                      color: "red",
                    }}
                  >
                    Couldn't fetch tasks
                  </span>
                )}

                {isLoading ? (
                  <div className="text-center">
                    <img
                      src={Loader}
                      alt="loader"
                      className="rounded-circle"
                      width="82"
                      height="82"
                    />
                  </div>
                ) : !isLoading && allTasks.length < 1 ? (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img src={NotStarted} alt="logo" width="180px" />
                    <br />
                    You have not started any tasks
                    <br />
                    <br />
                    <a className="" href="/all/tasks">
                      Start A Task
                    </a>
                  </div>
                ) : (
                  allTasks.map((task, index) => {
                    return (
                      <div
                        className="row mb-3 align-items-center"
                        key={`task-${index}`}
                        onClick={() =>
                          (window.location.href = `/review-task/${task?.utid}`)
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div className="card border rounded shadow lift mb-6 p-2">
                          <div className="card-footer p-4 p-md-5">
                            <a
                              href={`/review-task/${task?.utid}`}
                              className="d-block me-xl-12"
                            >
                              <h3 className="">{task?.title}</h3>
                            </a>

                            <p className="line-clamp-3 me-xl-5">
                              {task?.subtitle}
                            </p>
                            <div className="d-md-flex align-items-center">
                              <div className="mb-4 mb-md-0">
                                <h6 className="mb-0 font-size-sm">
                                  Status: &nbsp;{" "}
                                  {task.status.charAt(0).toUpperCase() +
                                    task.status.slice(1)}{" "}
                                  &nbsp; | &nbsp;
                                  <a href={`/review-task/${task.utid}`}>
                                    <span className="font-size-sm">
                                      View Feedback
                                    </span>
                                  </a>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <Subscribe />
          </div>
        </div>
      </section>
    </>
  );
};

export default MyTasks;
