import axios from "../config/axios";

export const getUserPoints = async () => {
  const request = await axios.get("/profile/getpoints");
  return request;
};

export const getUserPerks = async () => {
  const request = await axios.get("/profile/getallperks");
  return request;
};

export const addUserPerkApi = async (inputs) => {
  const request = await axios.post("/profile/claimperk", inputs);
  return request;
};