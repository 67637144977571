import React from "react";
import { useEffect } from "react";
import GrowthIcon from "../../assets/images/growth.png";

const Insight = () => {
  useEffect(() => {
    document && document.querySelector("body").classList.add("bg-white");
  }, []);
  return (
    <>
      <header className="bg-white border-bottom py-3">
        <div className="px-5 px-lg-8 w-100">
          <div className="d-md-flex align-items-center">
            <div className="ms-md-6 ms-wd-12 ms-xl-10 me-auto mb-5 mb-md-0">
              <h3 className="mb-0 line-clamp-2 ms-wd-3">
                <img src={GrowthIcon} width="30px" alt="icon" />
                Your Growth Insight
              </h3>
            </div>

            <a
              href="course-single-v1.html"
              className="btn btn-sm btn-primary  px-6 mb-3 mb-md-0 flex-shrink-0"
            >
              <i className="fas fa-arrow-left font-size-xs"></i>
              &nbsp;
              <span className="">Back Home &nbsp;</span>
              {/* <i className="fas fa-arrow-left font-size-xs"></i>
              <span className="ms-3">Back Home</span> */}
            </a>
          </div>
        </div>
      </header>

      <div className="container"></div>
    </>
  );
};

export default Insight;
