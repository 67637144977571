import React from "react";

const Footer = () => {
  return (
    <>
      <footer
        className="pt-11 pt-md-13 bg-gray-100 bottom-0"
        // style={{ position: "fixed", width: "100%" }}
      >
        <div className="container">
          <div className="row" id="accordionFooter">
            <div className="col-12 mt-md-5">
              <div className="border-top pb-5 pt-6 py-md-4 text-center text-xl-start d-flex flex-column d-md-block d-xl-flex flex-xl-row align-items-center">
                <p className="text-gray-800 font-size-sm-alone d-block mb-0 mb-md-2 mb-xl-0 order-1 order-md-0 ">
                  Copyright © {new Date().getFullYear()} LearnHQ. All Right
                  Reserved.
                </p>

                <div className="ms-xl-auto d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-center">
                  <ul className="navbar-nav flex-row flex-wrap font-size-sm-alone mb-3 mb-md-0 mx-n4 me-md-5 justify-content-center justify-content-lg-start order-1 order-md-0">
                    <li className="nav-item py-2 py-md-0 px-0 border-top-0">
                      <a
                        href="/"
                        className="nav-link px-4 fw-normal text-gray-800"
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item py-2 py-md-0 px-0 border-top-0">
                      <a
                        href="#?"
                        className="nav-link px-4 fw-normal text-gray-800"
                      >
                        Contact us: hello@mylearnhq.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
