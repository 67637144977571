import axios from "../config/axios";

export const getAllTasksApi = async () => {
  const request = await axios.get("/task/getall");
  return request;
};

export const getUserTasksApi = async () => {
  const request = await axios.get("/task/getusertasks");
  return request;
};

export const getSingleTasksApi = async (taskSlug) => {
  const request = await axios.get(`/task/getsingle/${taskSlug}`);
  return request;
};

export const getSingleUserTaskApi = async (userTaskId) => {
  const request = await axios.get(`/task/usertask/${userTaskId}`);
  return request;
};


export const getTaskReviewsApi = async (userTaskId) => {
  const request = await axios.get(`/task/reviews/${userTaskId}`);
  return request;
};


export const addUserTasksApi = async (inputs) => {
  const request = await axios.post("/task/create/usertask", inputs);
  return request;
};

export const submitTaskApi = async (inputs) => {
  const request = await axios.post("/task/submit", inputs);
  return request;
};
