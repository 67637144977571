import axios from "../config/axios";

export const signupApi = async (inputs) => {
  const request = await axios.post("/auth/signup/email", inputs);
  return request;
};

export const loginApi = async (inputs) => {
  const request = await axios.post("/auth/login/email", inputs);
  return request;
};

export const passwordResetApi = async (inputs) => {
  const request = await axios.post("/auth/password/reset", inputs);
  return request;
};

export const confirmPasswordResetApi = async (inputs) => {
  const request = await axios.put("/auth/password/completereset", inputs);
  return request;
};

export const verifyApi = async (input) => {
  const request = await axios.post("/auth/verify/login", {
    email: input.email,
    mid: input.magicId,
  });
  return request;
};
