import { Route } from "react-router-dom";
import NotFound from "../pages/shared/NotFound";
import Dashboard from "pages/dashboard";
import AllTasks from "pages/alltasks";
import MyTasks from "pages/mytasks";
import SingleTask from 'pages/singletask';
import ReviewTask from "pages/reviewtask";
import Insight from "pages/insights";
import PerkBox from "pages/perkbox/PerkBox";

export const authRoutes = [
  {
    type: Route,
    path: "/home",
    component: Dashboard,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/single-task/:id",
    component: SingleTask,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/my/insight",
    component: Insight,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/my/tasks",
    component: MyTasks,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/review-task/:id",
    component: ReviewTask,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/all/tasks",
    component: AllTasks,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "/perkbox",
    component: PerkBox,
    exact: true,
    isAuth: true,
  },
  {
    type: Route,
    path: "*",
    component: NotFound,
  },
];
