import React, { useState } from "react";
import { useMutation } from "react-query";
import Logo from "../../assets/images/learnhq-logo.png";

import { SignUpValidationSchema } from "utils/validationSchema";

import { signupApi } from "api/auth";
import { TOKEN_NAME } from "utils/constants";

const Signup = () => {
  const [inputType, setInputType] = useState("password");
  const [inputs, setInputs] = useState({
    email: "",
    username: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const signUserUp = useMutation(
    "addUser",
    async (data) => {
      const response = await signupApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        // const encodeCredit = btoa(data?.data?.updatedCredit);
        localStorage.setItem(TOKEN_NAME, data.data.token);
        window.location.href = "/home";
      },
      onError: (error) => {
        if (error?.response?.data?.statusCode === 409) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setErrorMessage("Failed to create account!");
        }
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    SignUpValidationSchema.validate(inputs, { abortEarly: false })
      .then(() => {
        const { email, username, password } = inputs;
        signUserUp.mutate({ username, email, password });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  const handleShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <>
      <header className="mt-8 py-7 py-md-5">
        <div className="container text-center py-xl-2">
          <a href="/">
            <img src={Logo} alt="logo" width="180px" />
          </a>
          <h5 className="fw-semi-bold mb-0" style={{
            fontSize: "25px"
          }}>Create Account</h5>
        </div>
      </header>
      <div className="container mb-11">
        <div className="row gx-0">
          <div className="col-md-7 col-xl-4 mx-auto">
            <form className="mb-5">
              {errorMessage && (
                <div style={styles.errorMessageBox}>{errorMessage}</div>
              )}
              <div className="form-group mb-5">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="example"
                  name="username"
                  value={inputs.username}
                  onChange={handleInputChange}
                />
                {<div style={styles.errorBox}>{errors.username}</div>}
              </div>
              <div className="form-group mb-5">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="creative@example.com"
                  name="email"
                  value={inputs.email}
                  onChange={handleInputChange}
                />
                {<div style={styles.errorBox}>{errors.email}</div>}
              </div>
              <div className="form-group mb-5">
                <label>Password</label>
                <input
                  type={inputType}
                  className="form-control"
                  placeholder="**********"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-check mb-3">
                <input
                  className="form-check-input text-gray-800"
                  type="checkbox"
                  onClick={handleShowPassword}
                />
                <label className="form-check-label text-gray-800">
                  Show Password
                </label>
              </div>
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={handleSubmit}
                disabled={signUserUp.isLoading}
              >
                {signUserUp.isLoading ? "Creating Account..." : "SIGNUP"}
              </button>
            </form>
            <p className="mb-0 font-size-sm text-center">
              Already have an account?{" "}
              <a className="text-underline" href="/login">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    textAlign: "left",
    marginBottom: "20px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default Signup;
