import React from "react";
import Logo from "../../assets/images/learnhq-logo.png";
import clearLocalStorage from "utils/clearData";

const NavBar = ({ isAuth }) => {
  const handleLogOut = () => {
    clearLocalStorage();
    window.location.href = "/login";
  };

  const goHome = () => {
    window.location.href = "/home";
  };

  return (
    <header className="navbar navbar-expand-xl navbar-light bg-white border-bottom border-xl-0 py-2 py-xl-0">
      <div className="container-fluid">
        <a className="navbar-brand me-0" href={isAuth ? "/home" : "/"}>
          <img src={Logo} alt="logo" width="130px" />
        </a>
        {isAuth && (
          <div style={{
            display: "flex"
          }}>
          <div  style={{
            color: "#0C0737",
            cursor: "pointer",
            marginRight: "10px"
          }}  onClick={goHome}>
            <span >
             <i className="fas fas fa-home font-size-xs"></i> Home &nbsp;|&nbsp;
            </span>
          </div>
          <div  style={{
            color: "#0C0737",
            cursor: "pointer"
          }}  onClick={handleLogOut}>
            <span   onClick={handleLogOut}>
             <i className="fas fas fa-sign-out-alt font-size-xs"></i> Logout
            </span>
          </div>
          </div>
          // <a
          //   href="#?"
          //   className="btn btn-xs btn-primary px-5 mb-2 mb-md-0 flex-shrink-0"
          //   onClick={handleLogOut}
          // >
          //   <span className="">
          //     <i className="fas fa-arrow-left font-size-xs"></i> &nbsp; Log Out
          //   </span>
          // </a>
        )}
      </div>
    </header>
  );
};

export default NavBar;
