import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Logo from "../../assets/images/learnhq-logo.png";

import { confirmPasswordResetApi } from "api/auth";

import { passwordResetConfirmValidationSchema } from "utils/validationSchema";
import { RESET_TOKEN_NAME } from "utils/constants";

const ConfirmPasswordReset = ({ match }) => {
  const [inputs, setInputs] = useState({
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [errors, setErrors] = useState({});

  const token = match?.params?.token;

  useEffect(() => {
    localStorage.setItem(RESET_TOKEN_NAME, token);
  }, [token]);

  const passwordResetConfirmRequest = useMutation(
    "passwordResetConfirm",
    async (data) => {
      const response = await confirmPasswordResetApi(data);
      return response;
    },
    {
      onSuccess: () => {
        setSuccessMessage(true);
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      },
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    passwordResetConfirmValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { password } = inputs;
        passwordResetConfirmRequest.mutate({
          password,
          token: localStorage.getItem(RESET_TOKEN_NAME),
        });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  return (
    <>
      <header className="mt-8 py-7 py-md-5">
        <div className="container text-center py-xl-2">
          <a href="/">
            <img src={Logo} alt="logo" width="180px" />
          </a>
          <h5
            className="fw-semi-bold mb-0"
            style={{
              fontSize: "25px",
            }}
          >
            Create New Password
          </h5>
        </div>
      </header>
      <div className="container mb-11">
        <div className="row gx-0">
          <div className="col-md-7 col-xl-4 mx-auto">
            <form className="mb-5" action="/home">
              {errorMessage && (
                <div style={styles.errorMessageBox}>
                  Couldn't update your password. Try Again!
                </div>
              )}
              {successMessage && (
                <div style={styles.successBox}>
                  Password updated succesfully!
                  <br />
                  <br />
                  Redirecting to login....
                </div>
              )}
              {!successMessage && (
                <div className="form-group mb-5">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="**********"
                    name="password"
                    value={inputs.usernameOrEmail}
                    onChange={handleInputChange}
                  />
                  {<div style={styles.errorBox}>{errors.usernameOrEmail}</div>}
                </div>
              )}

              {!successMessage && (
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={handleSubmit}
                  disabled={passwordResetConfirmRequest.isLoading}
                >
                  {passwordResetConfirmRequest.isLoading
                    ? "Updating..."
                    : "Update Password"}
                </button>
              )}
            </form>
            {/* <p className="mb-0 font-size-sm text-center">
              Already have an account?{" "}
              <a className="text-underline" href="/login">
                Login
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    textAlign: "left",
    marginBottom: "20px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default ConfirmPasswordReset;
