import React, { useState } from "react";
import { useMutation } from "react-query";
import Logo from "../../assets/images/learnhq-logo.png";

import { loginApi } from "api/auth";

import { loginValidationSchema } from "utils/validationSchema";
import { TOKEN_NAME } from "utils/constants";

const Login = () => {
  const [inputType, setInputType] = useState("password");
  const [inputs, setInputs] = useState({
    usernameOrEmail: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const loginUser = useMutation(
    "loginUser",
    async (data) => {
      const response = await loginApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(TOKEN_NAME, data.data.token);
        window.location.href = "/home";
      },
      onError: (error) => {
        setErrorMessage(error?.response?.data?.message);
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    loginValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { usernameOrEmail, password } = inputs;
        loginUser.mutate({ usernameOrEmail, password });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  const handleShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <>
      <header className="mt-8 py-7 py-md-5">
        <div className="container text-center py-xl-2">
          <a href="/">
            <img src={Logo} alt="logo" width="180px" />
          </a>
          <h5
            className="fw-semi-bold mb-0"
            style={{
              fontSize: "25px",
            }}
          >
            Welcome!
          </h5>
        </div>
      </header>
      <div className="container mb-11">
        <div className="row gx-0">
          <div className="col-md-7 col-xl-4 mx-auto">
            <form className="mb-5" action="/home">
              {errorMessage && (
                <div style={styles.errorMessageBox}>{errorMessage}</div>
              )}
              <div className="form-group mb-5">
                <label>Username or Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="creative@example.com"
                  name="usernameOrEmail"
                  value={inputs.usernameOrEmail}
                  onChange={handleInputChange}
                />
                {<div style={styles.errorBox}>{errors.usernameOrEmail}</div>}
              </div>

              <div className="form-group mb-5">
                <label>Password</label>
                <input
                  type={inputType}
                  className="form-control"
                  placeholder="**********"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputChange}
                />
                {<div style={styles.errorBox}>{errors.password}</div>}
              </div>

              <div className="d-flex align-items-center mb-5 font-size-md">
                <div className="form-check" onClick={handleShowPassword}>
                  <input
                    className="form-check-input text-gray-800"
                    type="checkbox"
                    onClick={handleShowPassword}
                  />
                  <label className="form-check-label text-gray-800">
                    Show Password
                  </label>
                </div>
                <div className="ms-auto">
                  <a className="text-underline" href="/password/reset">
                    Forgot Password
                  </a>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={handleSubmit}
                disabled={loginUser.isLoading}
              >
                {loginUser.isLoading ? "Logging in..." : "LOGIN"}
              </button>
            </form>
            <p className="mb-0 font-size-sm text-center">
              Don't have an account?{" "}
              <a className="text-underline" href="/signup">
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    textAlign: "left",
    marginBottom: "20px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default Login;
