import React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Code from "assets/images/code.png";
import Loader from "assets/images/ball-rolling.svg";

import Subscribe from "components/Subscribe/Subscribe";

import { getAllTasksApi } from "api/tasks";

// import Hero from "../../components/Hero";

const AllTasks = () => {
  useEffect(() => {
    document && document.querySelector("body").classList.add("bg-white");
  }, []);

  const [allTasks, setAllTasks] = useState([]);

  const { isLoading, isError } = useQuery(
    "getAllTasks",
    async () => {
      const response = await getAllTasksApi();
      return response;
    },
    {
      onSuccess: (data) => {
        setAllTasks(data?.data?.tasks);
      },
      staleTime: Infinity
    }
  );

  return (
    <>
      <section className=" border-top" style={{ backgroundImage: "none" }}>
        <div className="container">
          <div className="row mb-11">
            <div className="col-md-12 col-lg-9 col-xl-9 py-0">
              <div className="container">
                {/* <Hero /> */}
                <br />
                <nav aria-label="breadcrumb ">
                  <ol className="breadcrumb breadcrumb-scroll justify-content-left">
                    <li className="breadcrumb-item">
                      <a href="/home">
                        <span className="">
                          <i className="fas fa-arrow-left font-size-md"></i>
                          &nbsp; Back Home
                        </span>
                      </a>
                    </li>
                    <li
                      className="breadcrumb-item text-gray-800 active"
                      aria-current="page"
                    >
                      All Tasks
                    </li>
                  </ol>
                </nav>
                <br />
                <h2 className=" fw-semi-bold mb-4">
                  <i class="fa fa-tasks"></i> &nbsp;Practice Tasks
                </h2>
                {isError && (
                  <span
                    className="ms-2"
                    style={{
                      color: "red",
                    }}
                  >
                    Couldn't fetch tasks
                  </span>
                )}

                {isLoading ? (
                  <div className="text-center">
                    <img
                      src={Loader}
                      alt="loader"
                      className="rounded-circle"
                      width="82"
                      height="82"
                    />
                  </div>
                ) : !isLoading && allTasks.length < 0 ? (
                  "No tasks available"
                ) : (
                  allTasks.map((task, index) => {
                    return (
                      <div
                        className="row mb-3 align-items-center"
                        key={`task-${index}`}
                        onClick={() =>
                          (window.location.href = `/single-task/${task.slug}`)
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div className="card border rounded shadow lift mb-2 p-2">
                          <div className="card-footer p-4 p-md-5">
                            <a
                              href={`/single-task/${task.slug}`}
                              className="d-block me-xl-12"
                            >
                              <h3 className="">{task.title}</h3>
                            </a>

                            <p className="line-clamp-3 me-xl-5">
                              {task.subtitle}
                            </p>

                            <div className="d-md-flex align-items-center">
                              <div className="border rounded-circle d-inline-block mb-4 mb-md-0 me-4">
                                <div className="p-1">
                                  <img
                                    src={Code}
                                    alt="..."
                                    className="rounded-circle"
                                    width="32"
                                    height="32"
                                  />
                                </div>
                              </div>

                              <div className="mb-2 mb-md-0">
                                <a href="blog-single.html" className="d-block">
                                  <h6 className="mb-0">
                                    Category:{" "}
                                    <span className="font-size-sm">
                                      {task?.category
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                        task?.category?.slice(1)}
                                    </span>
                                    , &nbsp; Level:{" "}
                                    <span className="font-size-sm">
                                      {task?.level?.charAt(0)?.toUpperCase() +
                                        task?.level.slice(1)}
                                    </span>
                                  </h6>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <Subscribe />
          </div>
        </div>
      </section>
    </>
  );
};

export default AllTasks;
