import React from "react";
import { useEffect } from "react";
import Jobs from "../../assets/images/perk-icon.png";
import Challenge from "../../assets/images/challenge.png";
import Check from "../../assets/images/check.png";
// import GrowthIcon from "../../assets/images/growth.png";
import TaskFeeds from "../../assets/images/feed1.png";
import Subscribe from "components/Subscribe/Subscribe";
import Hero from "components/Hero";

const Dashboard = () => {
  useEffect(() => {
    document && document.querySelector("body").classList.add("bg-white");
  }, []);
  return (
    <section className="border-top">
      <div className="container">
        <div className="row mb-11">
          <div className="col-lg-9 mb-6 mb-lg-0">
            <section className="py-6 bg-white">
              <div className="container">
                <Hero />
                <div className="row align-items-end mb-md-7 mb-4 aos-init aos-animate">
                  <div className="col-md mb-4 mb-md-0">
                    <br />
                    <p className="font-size-md mb-0 py-3">
                      <span
                        style={{
                          color: "rgb(12 3 55)",
                        }}
                      >
                        Earn points solving micro-tasks to
                        access range of rewards on Perk Box. &nbsp;
                      </span>
                    </p>
                  </div>
                  <div className="col-md-auto py-3">
                    <a
                      href="/all/tasks"
                      className="d-flex align-items-center fw-medium"
                    >
                      Browse All Tasks
                      <div className="ms-2 d-flex">
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.7779 4.6098L3.32777 0.159755C3.22485 0.0567475 3.08745 0 2.94095 0C2.79445 0 2.65705 0.0567475 2.55412 0.159755L2.2264 0.487394C2.01315 0.700889 2.01315 1.04788 2.2264 1.26105L5.96328 4.99793L2.22225 8.73895C2.11933 8.84196 2.0625 8.97928 2.0625 9.1257C2.0625 9.27228 2.11933 9.4096 2.22225 9.51269L2.54998 9.84025C2.65298 9.94325 2.7903 10 2.9368 10C3.0833 10 3.2207 9.94325 3.32363 9.84025L7.7779 5.38614C7.88107 5.2828 7.93774 5.14484 7.93741 4.99817C7.93774 4.85094 7.88107 4.71305 7.7779 4.6098Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-4">
                  <div className="col mb-md-6 mb-4 px-2 px-md-4 aos-init aos-animate">
                    <a
                      href="/all/tasks"
                      className="card icon-category border shadow-dark p-md-5 p-3 text-center lift"
                    >
                      <div className="position-relative text-light">
                        <img src={Challenge} width="55px" alt="icon" />
                      </div>

                      <div className="card-footer px-0 pb-0">
                        <h5 className="mb-0 line-clamp-1">Practice Tasks</h5>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col mb-md-6 mb-4 px-2 px-md-4 aos-init aos-animate"
                    data-aos-delay="150"
                  >
                    <a
                      href="my/tasks"
                      className="card icon-category border shadow-dark p-md-5 p-3 text-center lift"
                    >
                      <div className="position-relative text-light">
                        <img src={Check} width="80px" alt="icon" />
                      </div>

                      <div className="card-footer px-0 pb-0">
                        <h5 className="mb-0 line-clamp-1">My Tasks</h5>
                      </div>
                    </a>
                  </div>

                  {/* <div className="col mb-md-6 mb-4 px-2 px-md-4 aos-init aos-animate">
                    <a
                      href="/my/insight"
                      className="card icon-category border shadow-dark p-md-5 p-3 text-center lift"
                    >
                      <div className="position-relative text-light">
                        <img src={GrowthIcon} width="70px" alt="icon" />
                      </div>

                      <div className="card-footer px-0 pb-0">
                        <h5 className="mb-0 line-clamp-1">Growth Insights</h5>
                      </div>
                    </a>
                  </div> */}
                     <div
                    className="col mb-md-6 mb-4 px-2 px-md-4 aos-init aos-animate"
                    data-aos-delay="150"
                  >
                    <a
                      href="/perkbox"
                      className="card icon-category border shadow-dark p-md-5 p-3 text-center lift"
                    >
                      <div className="position-relative text-light">
                        <img src={Jobs} width="65px" alt="icon" />
                      </div>

                      <div className="card-footer px-0 pb-0 ">
                        <h5 className="mb-0 line-clamp-1">Perk Box</h5>
                      </div>
                    </a>
                  </div>
                  <div className="col mb-md-6 mb-4 px-2 px-md-4 aos-init aos-animate">
                    <a
                      href="#?"
                      className="card icon-category border shadow-dark p-md-5 p-3 text-center lift"
                    >
                      <div className="position-relative text-light">
                        <img src={TaskFeeds} width="60px" alt="icon" />
                      </div>

                      <div className="card-footer px-0 pb-0 ">
                        <h5 className="mb-0 ">Learn Cards</h5>
                        <p>coming soon</p>
                      </div>
                    </a>
                  </div>
               
                </div>
              </div>
            </section>
          </div>

          <Subscribe />
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
