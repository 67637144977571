import React, { useState } from "react";
import { useMutation } from "react-query";
import Logo from "../../assets/images/learnhq-logo.png";

import { passwordResetApi } from "api/auth";

import { passwordResetValidationSchema } from "utils/validationSchema";

const PasswordReset = () => {
  const [inputs, setInputs] = useState({
    usernameOrEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [errors, setErrors] = useState({});

  const passwordResetRequest = useMutation(
    "passwordReset",
    async (data) => {
      const response = await passwordResetApi(data);
      return response;
    },
    {
      onSuccess: () => {
        setSuccessMessage(true)
      },
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    passwordResetValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { usernameOrEmail } = inputs;
        passwordResetRequest.mutate({ usernameOrEmail });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };


  return (
    <>
      <header className="mt-8 py-7 py-md-5">
        <div className="container text-center py-xl-2">
          <a href="/">
            <img src={Logo} alt="logo" width="180px" />
          </a>
          <h5 className="fw-semi-bold mb-0" style={{
            fontSize: "25px"
          }}>Password Reset</h5>
        </div>
      </header>
      <div className="container mb-11">
        <div className="row gx-0">
          <div className="col-md-7 col-xl-4 mx-auto">
            <form className="mb-5" action="/home">
              {errorMessage && (
                <div style={styles.errorMessageBox}>Couldn't send the password reset email!</div>
              )}
              {successMessage && (
                <div style={styles.successBox}>Password reset request sent succesfully!
                <br />
                <br />
                Please check your email.
                </div>
              )}
              {!successMessage && <div className="form-group mb-5">
                <label>Username or Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="creative@example.com"
                  name="usernameOrEmail"
                  value={inputs.usernameOrEmail}
                  onChange={handleInputChange}
                />
                {<div style={styles.errorBox}>{errors.usernameOrEmail}</div>}
              </div>}
        

              {!successMessage &&  <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={handleSubmit}
                disabled={passwordResetRequest.isLoading}
              >
                {passwordResetRequest.isLoading ? "Requesting..." : "Request Reset"}
              </button>}
            </form>
            <p className="mb-0 font-size-sm text-center">
              Already have an account?{" "}
              <a className="text-underline" href="/login">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    textAlign: "left",
    marginBottom: "20px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default PasswordReset;
