import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import People from "../../assets/images/lang-hero3.avif";
import Challenge from "../../assets/images/challenge.png";
import Solve from "../../assets/images/solve.png";
import Feedback from "../../assets/images/feedback.png";
import Htmllogo from "../../assets/images/html-logo.png";
import Csslogo from "../../assets/images/css-logo.png";
import Jslogo from "../../assets/images/js-logo.png";
import Pythonlogo from "../../assets/images/python-logo.png";

import Footer from "components/Footer";

const Home = () => {
  return (
    <>
      <NavBar />
      <section className="py-4 py-md-10 position-relative bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-5 col-lg-5 order-md-2">
              <img
                src={People}
                className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"
                alt="people"
              />
            </div>

            <div className="col-12 col-md-7 col-lg-7 order-md-1 px-md-0">
              <h2 className="">
              Get really good at <span style={{
                background: "yellow"
              }}>Coding</span> by <span style={{
                background: "yellow"
              }}>Solving Micro-Tasks</span>
                {/* <span className="text-orange">Solving Micro-Tasks</span> */}
              </h2>
              <p className="lead pe-md-8">
                Ready to level up your tech skills? Join others solving
                micro-challenges, get experts and community reviews, and
                collaborate to reach new heights!
              </p>
              <a
                href="/signup"
                className="btn btn-primary  btn-wide lift shadow mb-4 mb-md-0 me-md-5"
              >
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 pt-md-11 pb-md-10 bg-light text-center">
        <div className="container">
          <div className="mb-md-8 mb-4">
            <h1>How It Works</h1>
            {/* <p className="font-size-lg mb-0 text-capitalize">
            </p> */}
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 mb-md-0">
              <div
                className="p-5 d-inline-block rounded-circle mb-6"
                style={{ border: "1px solid #F8C994" }}
              >
                <div
                  className="icon-circle icon-circle-lg"
                  style={{ backgroundColor: "#F8C994", color: "#EE8E00" }}
                >
                  <img src={Challenge} width="60px" alt="icon" />
                </div>
              </div>

              <h4 className="mb-1">Choose a task</h4>
              <p className="">
                Pick from a list of micro-tasks that
                you feel will be a nice challenge for you at this stage.
              </p>
            </div>

            <div className="col-lg-3 col-md-6 mb-3 mb-md-0">
              <div
                className="p-5 d-inline-block rounded-circle mb-6"
                style={{ border: "1px solid #B7B3F8" }}
              >
                <div
                  className="icon-circle icon-circle-lg"
                  style={{ backgroundColor: "#B7B3F8", color: "#5066F5" }}
                >
                  <img src={Solve} width="80px" alt="icon" />
                </div>
              </div>

              <h4 className="mb-1">Build/Solve the task</h4>
              <p>Start the task. Building it is up to you!</p>
            </div>

            <div className="col-lg-3 col-md-6 mb-3 mb-md-0">
              <div
                className="p-5 d-inline-block rounded-circle mb-6"
                style={{ border: "1px solid #B2F4DC" }}
              >
                <div
                  className="icon-circle icon-circle-lg"
                  style={{ backgroundColor: "#B2F4DC", color: "#00C27C;" }}
                >
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.7188 39.7461C36.7188 40.5552 36.063 41.2109 35.2539 41.2109C34.4448 41.2109 33.7891 40.5552 33.7891 39.7461C33.7891 38.937 34.4448 38.2812 35.2539 38.2812C36.063 38.2812 36.7188 38.937 36.7188 39.7461Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M29.3945 17.7734H31.1108C33.3912 17.7734 35.5354 18.6615 37.1479 20.274C37.429 20.5555 37.8056 20.7031 38.184 20.7031C38.5735 20.7031 38.9503 20.5433 39.2193 20.274C40.1783 19.315 41.3261 18.6146 42.5781 18.2026V22.168C42.5781 22.9771 43.2339 23.6328 44.043 23.6328C44.8521 23.6328 45.5078 22.9771 45.5078 22.168V17.7734H46.9727C47.7818 17.7734 48.4375 17.1177 48.4375 16.3086V1.46484C48.4375 0.655746 47.7818 0 46.9727 0H45.2564C42.659 0 40.1939 0.857925 38.1836 2.4395C36.1732 0.857925 33.7082 0 31.1108 0H29.3945C28.5854 0 27.9297 0.655746 27.9297 1.46484V16.3086C27.9297 17.1177 28.5854 17.7734 29.3945 17.7734ZM45.5078 2.92969V14.8438H45.2564C43.2205 14.8438 41.3033 15.3725 39.6484 16.3033V5.03235C41.2033 3.67355 43.1721 2.92969 45.2564 2.92969H45.5078ZM30.8594 2.92969H31.1108C33.1944 2.92969 35.1643 3.67126 36.7188 5.0293V16.3029C35.025 15.3503 33.107 14.8438 31.1108 14.8438H30.8594V2.92969Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M3.02734 50H46.9727C47.7818 50 48.4375 49.3443 48.4375 48.5352V33.8867C48.4375 33.0776 47.7818 32.4219 46.9727 32.4219H33.6418C32.9613 29.0829 30.0018 26.5625 26.4648 26.5625H22.0703V25.0675C23.896 23.6919 25 21.5286 25 19.2383V16.6222C25 12.8906 22.3755 9.64966 18.895 9.08356C14.397 8.3519 10.3516 11.7859 10.3516 16.3086V19.2383C10.3516 21.5286 11.4555 23.6919 13.2812 25.0675V26.5625H8.88672C4.8481 26.5625 1.5625 29.8481 1.5625 33.8867V48.5352C1.5625 49.3443 2.21825 50 3.02734 50ZM45.5078 47.0703H25V35.3516H45.5078V47.0703ZM13.2812 16.3086C13.2812 13.6845 15.6109 11.5177 18.4246 11.9755C20.5029 12.3135 22.0703 14.3112 22.0703 16.6222V19.2383C22.0703 20.8031 21.2471 22.2164 19.8685 23.019C19.418 23.2811 19.1406 23.7633 19.1406 24.2851V27.4204L17.6758 28.8853L16.2109 27.4204V24.2851C16.2109 23.7637 15.9336 23.2811 15.4831 23.019C14.1045 22.2164 13.2812 20.8031 13.2812 19.2383V16.3086ZM4.49219 33.8867C4.49219 31.4636 6.46362 29.4922 8.88672 29.4922H14.1392L16.6401 31.9927C16.9258 32.2788 17.3008 32.4219 17.6758 32.4219C18.0508 32.4219 18.4258 32.2788 18.7115 31.9927L21.2124 29.4922H26.4648C28.3745 29.4922 30.0026 30.7167 30.6076 32.4219H23.5352C22.7261 32.4219 22.0703 33.0776 22.0703 33.8867V42.6796C20.8454 41.758 19.3233 41.2109 17.6758 41.2109H10.3516V33.8867C10.3516 33.0776 9.69582 32.4219 8.88672 32.4219C8.07762 32.4219 7.42188 33.0776 7.42188 33.8867V42.6758C7.42188 43.4849 8.07762 44.1406 8.88672 44.1406H17.6758C19.5858 44.1406 21.2147 45.3652 21.8193 47.0703H4.49219V33.8867Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <h4 className="mb-1">Submit your solution</h4>
              <p>
                Submit your solution to get reviews/feedback from experts and other in the community,
              </p>
            </div>

            <div className="col-lg-3 col-md-6 mb-3 mb-md-0">
              <div
                className="p-5 d-inline-block rounded-circle mb-6"
                style={{ border: "1px solid #e6d8f1" }}
              >
                <div
                  className="icon-circle icon-circle-lg"
                  style={{ backgroundColor: "#e6d8f1", color: "#5066F5" }}
                >
                  <img src={Feedback} width="50px" alt="icon" />
                </div>
              </div>

              <h4 className="mb-1">Build your profile</h4>
              <p>
                Get experience points solving these micro-tasks, and get rewarded for learning.
                
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 pt-md-11 pb-md-12 text-center">
        <div className="container">
          <div className="mb-8">
            <h2 className="mb-1">Explore and get proficient in:</h2>
            {/* <p className="font-size-lg mb-0">Explore and get fluent in:</p> */}
          </div>

          <div className="row text-capitalize">
            <div className="col-md-3 mb-5">
              <div className="card border shadow lift px-4 pt-6 pb-8">
                <div className="d-inline-block rounded-circle mb-4">
                  <div
                    className="icon-circle icon-circle-lg"
                    style={{ backgroundColor: "#EDEAF6", color: "#4E35A3" }}
                  >
                    <img src={Htmllogo} width="50px" alt="icon" />
                  </div>
                </div>

                <h5>HTML</h5>
                {/* <p className="px-2 mb-0">
                  Have a look through our list of micro-challenges. Pick one
                  that you feel will be a nice challenge for you at this stage.
                </p> */}
              </div>
            </div>

            <div className="col-md-3 mb-5">
              <div className="card border shadow lift px-4 pt-6 pb-8">
                <div className="d-inline-block rounded-circle mb-4">
                  <div
                    className="icon-circle icon-circle-lg"
                    style={{ backgroundColor: "#E8F8FB", color: "#17B5D1" }}
                  >
                    <img src={Csslogo} width="50px" alt="icon" />
                  </div>
                </div>

                <h5>CSS</h5>
              </div>
            </div>

            <div className="col-md-3 mb-5">
              <div className="card border shadow lift px-4 pt-6 pb-8">
                <div className="d-inline-block rounded-circle mb-4">
                  <div
                    className="icon-circle icon-circle-lg"
                    style={{ backgroundColor: "#FFEBE5", color: "#FD5000" }}
                  >
                    <img src={Jslogo} width="50px" alt="icon" />
                  </div>
                </div>

                <h5>Javascript</h5>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="card border shadow lift px-4 pt-6 pb-8">
                <div className="d-inline-block rounded-circle mb-4">
                  <div
                    className="icon-circle icon-circle-lg"
                    style={{ backgroundColor: "#FFEBE5", color: "#FD5000" }}
                  >
                    <img src={Pythonlogo} width="50px" alt="icon" />
                  </div>
                </div>

                <h5>Python</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="py-6 py-md-11 border-top"
        style={{ backgroundImage: "none" }}
      >
        <div className="container text-center py-xl-4 aos-init aos-animate">
          <h1 className="text-capitalize">JOIN OUR COMMUNITY</h1>
          <div className="font-size-lg mb-md-6 mb-4">
            Enhance your skills while collaborating with others
          </div>
          <div className="mx-auto">
            <a
              href="/signup"
              className="btn btn-primary btn-x-wide lift d-inline-block"
            >
              GET STARTED NOW
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
