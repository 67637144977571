import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import Subscribe from "../../components/Subscribe/Subscribe";
import Badge from "../../assets/images/badge.png";
import CircleLoader from "../../assets/images/circle-loader.svg";

import { getUserPerks, addUserPerkApi } from "api/profile";

const PerkBox = () => {
  const [allUserPerks, setAllUserPerks] = useState([]);
  const [selectedPerk, setSelectedPerk] = useState('');

  const { isLoading, isError, refetch: fetchAllPerks } = useQuery(
    "getUserPerks",
    async () => {
      const response = await getUserPerks();
      return response;
    },
    {
      onSuccess: (data) => {
        const allPerks = data?.data?.result?.map(element => {
          return element.perkid
        }) || [];

        setAllUserPerks(allPerks);
      },
    },
  );

  const claimMyPerk = useMutation(
    "addUserPerk",
    async (data) => {
      const response = await addUserPerkApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        const newArray = [...allUserPerks, data.perkId];
        setAllUserPerks(newArray);
        toast.success("You've successfully clained the perk.");
        setSelectedPerk('')
        fetchAllPerks();
      },
      onError: () => {
        toast.error("Perk could not be added. Try again!");
      },
    }
  );

  useEffect(() => {
    fetchAllPerks()
  }, [fetchAllPerks])

  const isPerkTaken = (perkId) => {
    return allUserPerks ? allUserPerks?.includes(perkId) : false;
  };

  return (
    <>
      <section className=" border-top" style={{ backgroundImage: "none" }}>
        <div className="container">
          <div className="row mb-11">
            <div className="col-md-9 col-lg-9 col-xl-9 py-2">
              <div className="container">
                <br />
                <h3 className="fw-semi-bold">
                  <img src={Badge} alt="logo" width="30px" />
                  &nbsp;Perk Box
                </h3>
                <div style={{
                  padding: "5px",
                  textAlign: "center",
                  background: "#000",
                  color: "#fff"

                }}>
                  Note: Only one of these CodeStart rewards can be claimed 
                </div>
                <br />
                {isError && (
                  <span
                    className="ms-2"
                    style={{
                      color: "red",
                    }}
                  >
                    Couldn't fetch perks
                  </span>
                )}
                <div className="row mb-1 align-items-center">
                  <div className="card border rounded shadow lift mb-6 p-0">
                    <div className="card-footer p-4 p-md-5">
                      {/* <a href={`#`} className="d-block me-xl-12"> */}
                      <h3 className="">CodeStart (Web Development)</h3>
                      {/* </a> */}

                      <p className="line-clamp-3 me-xl-5">
                        Claim the CodeStart Reward to join the next bootcamp
                        program
                      </p>
                      {isLoading ? (
                        <img src={CircleLoader} alt="bar-loader" width="40px" />
                      ) : (
                        <div className="d-md-flex align-items-center">
                          <div className="mb-4 mb-md-0">
                            <button
                              className="btn btn-primary"
                              type="button"
                              name="button"
                              disabled={
                                (isPerkTaken("p124343546") || allUserPerks.length > 0) || (
                                  claimMyPerk.isLoading & selectedPerk === "p124343546")
                              }
                              onClick={() => {
                                if (!isPerkTaken("p124343546")) {
                                  claimMyPerk.mutate({
                                    perkId: "p124343546",
                                  });
                                }
                              }}
                            >
                              &nbsp;{" "}
                              {selectedPerk === "p124343546" && claimMyPerk.isLoading
                                ? "Loading..."
                                : isPerkTaken("p124343546")
                                ? "Reward Claimed"
                                : "Claim Reward"}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="card border rounded shadow lift mb-6 p-2">
                    <div className="card-footer p-4 p-md-5">
                      {/* <a href={`#`} className="d-block me-xl-12"> */}
                      <h3 className="">CodeStart (JS/ReactJs)</h3>
                      {/* </a> */}

                      <p className="line-clamp-3 me-xl-5">
                        Claim the CodeStart Reward to join the next bootcamp
                        program
                      </p>
                      {isLoading ? (
                        <img src={CircleLoader} alt="bar-loader" width="40px" />
                      ) : (
                        <div className="d-md-flex align-items-center">
                          <div className="mb-4 mb-md-0">
                            <button
                              className="btn btn-primary"
                              type="button"
                              name="button"
                              disabled={
                                (isPerkTaken("p124343547") || allUserPerks.length > 0 ) || (
                                claimMyPerk.isLoading & selectedPerk === "p124343547")
                              }
                              onClick={() => {
                                if (!isPerkTaken("p124343547") && allUserPerks.length < 1) {
                                  setSelectedPerk("p124343547")
                                  claimMyPerk.mutate({
                                    perkId: "p124343547",
                                  });
                                }
                              }}
                            >
                              &nbsp;{" "}
                              {selectedPerk === "p124343547" && claimMyPerk.isLoading
                                ? "Loading..."
                                : isPerkTaken("p124343547")
                                ? "Reward Claimed"
                                : "Claim Reward"}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Subscribe />
          </div>
        </div>
      </section>
    </>
  );
};

export default PerkBox;
