import * as yup from "yup";

// const nameRegex = /^[a-zA-Z]*$/;

export const SignUpValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email address"),
  username: yup
    .string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters"),
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters"),
});

export const loginValidationSchema = yup.object().shape({
  usernameOrEmail: yup.string().required("Email/Username is required"),
  password: yup
    .string()
    .required("Password is required")
});

export const passwordResetValidationSchema = yup.object().shape({
  usernameOrEmail: yup.string().required("Email/Username is required"),
});

export const passwordResetConfirmValidationSchema = yup.object().shape({
  password: yup
  .string()
  .required("Password is required")
  .min(4, "Password must be at least 4 characters"),
});


export const submitTaskValidationSchema = yup.object().shape({
  submiturl: yup
    .string()
    .required("Url is required")
    .matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/,
      "Enter valid url"
    ),
});
